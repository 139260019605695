<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_add_person">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @invalid-submit="onInvalidSubmit"
        @submit="onSubmit"
        :initial-values="id_person ? person : formValues"
        :validation-schema="schema"
        v-slot="{ values }"
        autocomplete="off"
      >
        <CropperModal
          :id="id_modal_cropper"
          :imageUrl="
            person && person.photo && !person.photo.includes('default')
              ? `${blob}${person.photo}`
              : ''
          "
          @fileRitagliato="fileRitagliato"
          :isOpenModal="isOpenModal"
          @closeModal="isOpenModal = false"
        />
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ id_person ? "Modifica persona" : "Inserimento persona" }}
            </h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="kt_modal_add_person_closex"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>
          <template v-if="(id_person && loaded_person) || !id_person">
            <div class="modal-body">
              <div class="card-body p-6">
                <div class="ps-5 pe-5 repeater-form">
                  <div class="row pb-4">
                    <div class="col-3">
                      <label class="fw-bold text-gray-700 pe-3">Cognome</label>
                      <Field name="surname" class="form-control" />
                      <ErrorMessage v-show="view_errors" name="surname" />
                    </div>
                    <div class="col-3">
                      <label class="fw-bold text-gray-700 pe-3">Nome</label>
                      <Field name="name" class="form-control" />
                      <ErrorMessage v-show="view_errors" name="name" />
                    </div>

                    <div class="col-3" v-if="!isGuest">
                      <label class="fw-bold text-gray-700 pe-3">Gruppo </label>
                      <Field
                        class="form-select"
                        name="id_group"
                        as="select"
                        @change="
                          values.id_group_accreditation =
                            $event.target.options[
                              $event.target.selectedIndex
                            ].dataset.accredito
                        "
                      >
                        <option
                          v-for="group in lookupGroup"
                          :key="group.id"
                          :value="group.id"
                          :data-accredito="group.id_group_accreditation"
                        >
                          {{ group.label }}
                        </option>
                      </Field>

                      <ErrorMessage v-show="view_errors" name="id_group" />
                    </div>
                    <div :class="isGuest ? 'col-6' : 'col-3'">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Gruppo Accrediti
                      </label>
                      <Field
                        class="form-select"
                        name="id_group_accreditation"
                        as="select"
                      >
                        <option
                          v-for="group in lookupGroupAccreditation"
                          :key="group.id"
                          :value="group.id"
                        >
                          {{ group.label }}
                        </option>
                      </Field>

                      <ErrorMessage
                        v-show="view_errors"
                        name="id_group_accreditation"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-3">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Numero di telefono</label
                      >
                      <Field name="phone_number" class="form-control" />
                      <ErrorMessage v-show="view_errors" name="phone_number" />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-700 pe-3">Email </label>
                      <Field name="email" class="form-control" type="email" />
                      <ErrorMessage v-show="view_errors" name="email" />
                    </div>
                    <div :class="isGuest ? 'col-6' : 'col-3'">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Data nascita</label
                      >
                      <!-- textInput -->

                      <VueDatePicker
                        placeholder="Data di nascita"
                        :max-date="new Date()"
                        format="dd/MM/yyyy"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        text-input
                        v-model="date_of_birth_var"
                        @update:modelValue="
                          date_of_birth_var = $event
                            ? moment($event).format('YYYY-MM-DD')
                            : null
                        "
                      />
                      <Field
                        name="date_of_birth"
                        type="hidden"
                        v-model="date_of_birth_var"
                        class="form-control"
                      />
                      <ErrorMessage v-show="view_errors" name="date_of_birth" />
                    </div>
                    <!-- <div class="col-md-3">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Codice fiscale
                      </label>
                      <Field name="tax_code" class="form-control" />
                      <ErrorMessage v-show="view_errors" name="tax_code" />
                    </div> -->
                    <div class="col-3">
                      <label class="fw-bold text-gray-700 pe-3"
                        >Lingua parlata
                      </label>
                      <Field class="form-select" name="id_language" as="select">
                        <option
                          v-for="language in lookupLanguage"
                          :key="language.id"
                          :value="language.id"
                        >
                          {{ language.label }}
                        </option>
                      </Field>

                      <ErrorMessage v-show="view_errors" name="id_language" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-start">
              <div class="row w-100">
                <div
                  class="ps-0 col-1 text-center"
                  v-if="
                    person && person.photo && !person.photo.includes('default')
                  "
                >
                  <!-- data-bs-toggle="modal"
                    :data-bs-target="`#cropperModal1`"  cursor-pointer-->

                  <div class="my-1" @click="isOpenModal = true">
                    <input
                      type="file"
                      class="d-none"
                      name="avatar"
                      accept=".jpg, .jpeg, .png"
                      id="addAvatarOspite"
                      @change="onInputChange"
                    />

                    <img
                      v-if="!person.photo.includes('default')"
                      :src="`${blob}${person.photo}&t=${timestamp}`"
                      class="rounded-circle width-100 bg-black"
                      alt="..."
                      :style="
                        person.photo.includes('default')
                          ? ''
                          : 'border: 2px solid #cbcbcb'
                      "
                      style="width: 5rem; height: 5rem"
                    />
                    <ErrorMessage name="photo" />
                    <!-- <ProfilePictureX /> -->
                  </div>
                </div>
                <div
                  :class="
                    person && person.photo && !person.photo.includes('default')
                      ? 'col-7'
                      : 'col-8'
                  "
                  class="d-flex align-items-center"
                >
                  <div
                    v-if="id_person"
                    @click="stampaBraccialetto"
                    style="cursor: pointer"
                    class="pt-1"
                  >
                    <i class="bi bi-printer fs-4 text-dark"></i>
                  </div>
                  <p
                    v-if="id_person"
                    class="ms-2 mb-0 text-center align-middle pt-1"
                  >
                    {{ person.barcode }}
                  </p>
                  <div
                    v-if="id_person"
                    @click="stampaBraccialetto"
                    style="cursor: pointer"
                    class="pt-1 ms-3"
                  >
                    <i
                      class="bi bi-qr-code fs-4"
                      :class="
                        !person.GUEST_ACCREDITATION
                          ? 'text-dark'
                          : person.badge
                          ? 'text-success'
                          : 'text-danger'
                      "
                    ></i>
                  </div>
                  <div class="d-flex flex-column">
                    <Field
                      name="accreditation_number"
                      class="form-control ms-2 mb-2"
                      style="min-width: 24rem"
                      placeholder="Numero accredito"
                    />
                    <ErrorMessage
                      v-show="view_errors"
                      name="accreditation_number"
                    />
                  </div>
                </div>
                <div
                  class="col-4 d-flex align-items-center justify-content-end"
                >
                  <button
                    type="button"
                    class="badge bg-light-danger rounded text-danger fs-6 me-2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="kt_modal_add_person_close_button"
                  >
                    Chiudi
                  </button>
                  <button
                    type="submit"
                    class="badge bg-light-success text-success rounded blueFit fs-6"
                  >
                    Salva
                  </button>
                </div>
              </div>
            </div>
          </template>
          <Loader v-else />
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import moment from "moment";
import { format, dateToReset } from "@/functions/formatDate";

import { callFunctionAfterAlert, alertFailed } from "@/functions/swAlert";
import { API } from "@/enums/costanti";
import { useApiCall } from "@/composables/apiCall";
import { getBraccialetto } from "@/functions/getBraccialetto";
import CropperModal from "@/components/user-sessions/CropperModal.vue";
// import { usePrinter } from "@/composables/composableStampante";
import useFileList from "@/composables/file-list";
import { fileSender } from "@/db/dbManag";

export default {
  name: "add-persona-modal",
  components: {
    CropperModal,
    VueDatePicker,
    Field,
    ErrorMessage,
    Form,
  },
  props: {
    id_person: {
      type: Number,
    },
    isGuest: {
      type: Boolean,
    },
  },
  emits: ["getList"],
  setup(props, { emit }) {
    const view_errors = ref(false);
    const disableButton = ref(false);
    const onInvalidSubmit = () => {
      view_errors.value = true;
      alertFailed("Inserire i campi obbligatori");
    };
    const onSubmit = async (values, { resetForm }) => {
      values.id_person = props.id_person;
      await callFunctionAfterAlert(
        props.id_person
          ? API.ANAGRAPHICS_PEOPLE_EDIT
          : API.ANAGRAPHICS_PEOPLE_ADD,
        values,
        `Sei sicuro di voler ${
          props.id_person ? "modificare" : "creare"
        } la persona <b>${values.surname} ${values.name}</b>?`,
        `${
          props.id_person
            ? "Persona modificata con successo"
            : "Persona creata con successo"
        }`,
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then((isAddedorModifiedPerson) => {
        if (!props.id_person) {
          date_of_birth_var.value = null;
        }

        if (isAddedorModifiedPerson) {
          document.getElementById("kt_modal_add_person_close_button").click();
          emit("getList");
          setTimeout(() => {
            resetForm();
          }, 500);
        }
      });
    };
    const schema = yup.object().shape({
      name: yup.string().required("Obbligatorio"),
      surname: yup.string().required("Obbligatorio"),
      date_of_birth: yup.string().nullable(),
      tax_code: yup.string().nullable(),
      phone_number: yup.string().nullable(),
      email: yup
        .string()
        .nullable()
        .when(["id_group"], {
          is: (id_group) => {
            return id_group != -1;
          },
          then: (schema) =>
            schema.email("Inserire una email valida").required("Obbligatorio"),
        }),
      id_language: yup.number().nullable(),
      id_group: yup.number().when(["id_language"], {
        is: () => {
          return person.value && person.value.id_group === null;
        },
        then: (schema) => schema.nullable(),
        otherwhise: (schema) =>
          schema
            .number()
            .min(-1, "Obbligatorio")
            .nullable()
            .required("Obbligatorio"),
      }),
      id_group_accreditation: yup
        .number()
        .min(1, "Obbligatorio")
        .nullable()
        .required("Obbligatorio"),
      accreditation_number: yup.string().nullable(),
      // .test(
      //   "len",
      //   "Deve avere minimo 14 caratteri",
      //   (val) => (val && val.length >= 14) || !val
      // ),
    });

    const date_of_birth_var = ref(null);

    const payload = ref({ id_person: props.id_person });

    const {
      element: personResults,
      loaded: loaded_person,
      getElement: get_person,
      status: status_person,
    } = useApiCall(API.ANAGRAPHICS_PEOPLE_VIEW, payload);

    const { element: lookups, getElement: get_lookups } = useApiCall(
      API.LOOKUP_GET,
      { keys: "ll|gg|ga" }
    );

    get_lookups();

    watch(
      () => props.id_person,
      async (newVal) => {
        if (newVal) {
          payload.value.id_person = newVal;
          await get_person();
          date_of_birth_var.value =
            personResults.value.results[0].date_of_birth;
        } else {
          personResults.value = null;
          date_of_birth_var.value = null;
          person.value = computed(() =>
            props.id_person && personResults.value
              ? personResults.value.results[0]
              : {}
          );
        }
      }
    );

    const formValues = ref({
      id_language: 1,
      id_group: 1,
    });
    watch(
      () => props.isGuest,
      () => {
        if (props.isGuest) {
          formValues.value = {
            id_language: 1,
            id_group: -1,
          };
        }
      }
    );

    const person = computed(() =>
      props.id_person && personResults.value
        ? personResults.value.results[0]
        : {}
    );

    // const printer = usePrinter();

    const BrowserPrint = window.BrowserPrint;
    const device = ref(null);
    const isPrinting = ref(false);
    BrowserPrint.getLocalDevices(function (deviceList) {
      device.value = deviceList.printer[0];
    });

    const payload_click = ref();
    const {
      element: res_click,
      getElement: click_print,
      loaded: loaded_print,
      status: status_print,
    } = useApiCall(API.SEATS_SESSION_PRINT_CLICK, payload_click);

    const stampaBraccialetto = async () => {
      payload_click.value = {
        id_person: person.value.id_person,
        id_event: props.id_event,
        id_year: props.id_year,
      };
      await click_print();
      const cmds = getBraccialetto(
        person.value.surname,
        person.value.name,
        person.value[person.value.print_code_field],
        person.value.lounge
      );

      if (device.value) {
        isPrinting.value = true;
        device.value.send(
          cmds,
          function (success) {
            isPrinting.value = false;
          },
          function (error) {
            console.error("Error:" + error);
            isPrinting.value = false;
          }
        );
      }
    };

    const id_modal_cropper = ref(1);

    const file_controllato = ref(false);
    const isLoading = ref(false);
    const { file, addFile, removeFile } = useFileList();

    const fileRitagliato = (fileRitagliato) => {
      console.log(fileRitagliato);
      file.value.file = fileRitagliato;
    };
    watch(
      () => file.value.file,
      (value) => {
        if (file.value.file) {
          caricaFoto();
        } else {
          setTimeout(() => {
            file_controllato.value = false;
          }, 1000);
        }
      }
    );

    const caricaFoto = async () => {
      console.log("caricaFoto", file.value);
      if (!file.value.file) {
        alertFailed(
          "Selezionare un gruppo e caricare un file per procedere con il controllo"
        );
        return;
      }
      file_controllato.value = false;
      isLoading.value = true;
      const bodyFormData = ref(new FormData());
      bodyFormData.value.append("file", file.value.file);
      bodyFormData.value.append("item_id", props.invito.id_user);
      bodyFormData.value.append("id_invitation", props.invito.id);
      bodyFormData.value.append("id_event", props.invito.id_event);
      bodyFormData.value.append("id_user", props.invito.id_user);

      await fileSender(API.ANAGRAPHICS_PHOTO_EDIT, bodyFormData.value).then(
        (res) => {
          file_controllato.value = true;
          isLoading.value = false;
          const { status, data } = res;
          if (status != 200) {
            file.value.status = false;
            alertFailed(
              data && data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            // errori_txt.value =
            //   data && data.message
            //     ? data.message
            //     : "Attenzione! Si è verificato un errore. Riprovare più tardi";
          } else {
            file.value.status = true;
            // QUI DA VEDERE COME SARA LA STRUTTURA DEL JSON
            // errori_txt.value = data && data.errori ? data.errori : "";

            emit("refresh");
          }
          file_controllato.value = false;
        }
      );
    };

    return {
      isLoading,
      file_controllato,
      file,
      fileRitagliato,
      id_modal_cropper,
      person,
      stampaBraccialetto,
      formValues,
      lookups,
      personResults,
      loaded_person,
      status_person,
      moment,
      format,
      date_of_birth_var,
      schema,
      onSubmit,
      onInvalidSubmit,
      disableButton,
      view_errors,
      blob: process.env.VUE_APP_BLOB,
    };
  },
  computed: {
    lookupLanguage() {
      return this.lookups &&
        this.lookups.result[0] &&
        this.lookups.result[0].languages
        ? this.lookups.result[0].languages
        : [];
    },
    lookupGroup() {
      return this.lookups &&
        this.lookups.result[1] &&
        this.lookups.result[1].groups
        ? this.lookups.result[1].groups
        : [];
    },
    lookupGroupAccreditation() {
      return this.lookups &&
        this.lookups.result[2] &&
        this.lookups.result[2].groups_accreditation
        ? this.lookups.result[2].groups_accreditation
        : [];
    },
  },
};
</script>

<style scoped>
.modal-content {
  width: 150%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(
    .border-active
  ):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}
ErrorMessage {
  color: red;
}
</style>
