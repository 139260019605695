<template>
  <div>
    <div class="row mt-5">
      <div class="col-3">
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6 text-start"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_add_person"
          @click="selected_id_person = null"
        >
          <i class="bi bi-plus fs-6 text-gray-700"></i>aggiungi anagrafica
          persona
        </button>
      </div>
      <div class="col-3">
        <button
          class="btn btn-light text-gray-700 fs-6 text-start"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_add_group_csv"
          type="button"
        >
          <i class="bi bi-upload fs-6 text-gray-700"></i> importazione gruppo
        </button>
        <ModalAddPerson
          :id_person="selected_id_person"
          :isGuest="isGuest"
          @getList="
            $emit('getList');
            selected_id_person = null;
          "
        />
      </div>
      <div class="col-3">
        <button
          class="btn btn-light text-gray-700 fs-6 text-start"
          type="button"
          @click="downloadModelloImport"
        >
          <i class="bi bi-download fs-6 text-gray-700"></i> scarica modello di
          importazione
        </button>
      </div>
      <div class="col-3">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="objectList && objectList.record > 0">
            {{
              payload.rowstoskip + payload.fetchrows - (payload.fetchrows - 1)
            }}
            -
            {{
              payload.rowstoskip + payload.fetchrows > objectList.record
                ? objectList.record
                : payload.rowstoskip + payload.fetchrows
            }}
          </b>
          <b v-else> 0 </b>
          persone di
          <b>{{ objectList && objectList.record ? objectList.record : 0 }}</b>
        </div>
      </div>
    </div>
    <br />

    <Datatable
      :key="loaded"
      :table-header="tableHeader"
      :table-header-2="tableHeader2"
      :table-data="objectList ? objectList.results : []"
      :status="status"
      :loading="!loaded"
      :enableItemsPerPageDropdown="
        objectList && objectList.record > 25 ? true : false
      "
      :total="objectList ? objectList.record : 0"
      :rowsPerPage="payload.fetchrows"
      :currentPage="payload.rowstoskip ? currentPage : 1"
      :order="payload.sortorder"
      :sortLabel="payload.sortcolumn"
      @sort="
        $emit('setPayload', {
          name: 'sortcolumn',
          value: $event.columnName,
        });
        $emit('setPayload', {
          name: 'sortorder',
          value: $event.order,
        });
        $emit('getList');
      "
      @items-per-page-change="
        $emit('setPayload', {
          name: 'fetchrows',
          value: $event,
        });
        $emit('getList');
      "
      @current-change="
        currentPage = $event;
        $emit('setPayload', {
          name: 'rowstoskip',
          value: payload.fetchrows * ($event - 1),
        });
        $emit('getList');
      "
      @resetFilters="$emit('resetFilters')"
    >
      <template v-slot:cell-cognome="{ row: data }"
        ><div class="">
          {{ data.surname }}
        </div>
      </template>
      <template v-slot:cell-nome="{ row: data }"
        ><div class="">
          {{ data.name }}
        </div>
      </template>
      <template v-slot:cell-data_nascita="{ row: data }">
        <div class="tab-long d-flex">
          <div class="">
            {{
              data.date_of_birth
                ? formatDateITANoTime(data.date_of_birth)
                : "Da inserire"
            }}
          </div>
          <Popper arrow class="col-3" v-if="!isMaggiorenne(data.date_of_birth)">
            <button type="button">
              <i
                class="bi bi-exclamation-triangle-fill text-danger text-center"
              ></i>
            </button>
            <template #content>
              <div>Minorenne</div>
            </template>
          </Popper>
        </div>
      </template>
      <template v-slot:cell-language="{ row: data }"
        ><div class="">
          {{ data.language }}
        </div>
      </template>
      <template v-slot:cell-email="{ row: data }"
        ><div
          class=""
          v-if="data && data.email && !data.email.includes('ospite')"
        >
          {{ data.email }}
        </div>
        <div v-else></div>
      </template>
      <template v-slot:cell-group="{ row: data }"
        ><div class="">
          <EtichettaGruppo :label="data.group" :bg_color="data.group_color" />
        </div>
      </template>
      <template v-slot:cell-group_accreditation="{ row: data }"
        ><div class="">
          <EtichettaGruppo
            :label="data.group_accreditation"
            bg_color="#5814EB"
          />
        </div>
      </template>

      <template v-slot:cell-options="{ row: data }"
        ><div class="btn-group">
          <button
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#TorneiRaas_panel_${data.id}`"
            aria-expanded="true"
            :aria-controls="`#TorneiRaas_panel_${data.id}`"
            v-if="data.accompagnatori && data.accompagnatori.length"
          >
            <i class="bi bi-person-down text-dark fs-4"></i>
          </button>
          <button
            class="btn btn-sm dropdown p-1"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_person"
            type="button"
            @click="
              selected_id_person = data.id;
              isGuest = false;
            "
          >
            <i class="bi bi-pen text-dark fs-4"></i>
          </button>
          <button
            v-if="3 == 4"
            class="btn btn-sm dropdown p-1"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            @click="deleteElement(data.id, `${data.surname} ${data.name}`)"
          >
            <i class="bi bi-trash text-dark fs-4"></i>
          </button></div
      ></template>
      <template v-slot:cell-accompagnatori="{ row: data, index: idx }">
        <div v-if="data.accompagnatori && data.accompagnatori.length">
          <div
            :id="`TorneiRaas_panel_${data.id}`"
            class="accordion-collapse collapse"
            :aria-labelledby="`TorneiRaas_int_${data.id}`"
            :style="
              idx % 2 == 0
                ? 'background-color: #f8fafb'
                : 'background-color: #ffffff'
            "
          >
            <div class="accordion-body">
              <table class="table tablegy-3 gs-7 align-top">
                <thead>
                  <tr
                    class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
                  >
                    <th>Cognome</th>
                    <th>Nome</th>
                    <th>Data Nascita</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(guest, index) in data.accompagnatori"
                    :key="index"
                  >
                    <td>{{ guest.surname }}</td>
                    <td>{{ guest.name }}</td>
                    <td>
                      {{
                        guest.date_of_birth
                          ? formatDateITANoTime(guest.date_of_birth)
                          : "Da inserire"
                      }}<Popper
                        arrow
                        class="col-3"
                        v-if="!isMaggiorenne(guest.date_of_birth)"
                      >
                        <button type="button">
                          <i
                            class="bi bi-exclamation-triangle-fill text-danger text-center"
                          ></i>
                        </button>
                        <template #content>
                          <div>Minorenne</div>
                        </template>
                      </Popper>
                    </td>
                    <td class="text-end table-test">
                      <div class="btn-group">
                        <button
                          class="btn btn-sm dropdown p-1 ms-auto"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_add_person"
                          type="button"
                          @click="
                            selected_id_person = guest.id;
                            isGuest = true;
                          "
                        >
                          <i class="bi bi-pen text-dark fs-4"></i>
                        </button>
                      </div>
                    </td>
                    <td class=""></td>
                    <!-- <td>
                      <div class="btn btn-sm dropdown p-1">
                        <Popper arrow class="ms-2">
                          <button type="button">
                            <i class="bi bi-info-circle-fill text-center"></i>
                          </button>
                          <template #content>
                            <div>Sessioni:</div>
                            <div
                              v-for="sessione in guest.sessioni"
                              :key="sessione.id"
                              style="min-width: 10rem"
                            >
                              {{ sessione.session_name }}
                            </div>
                          </template>
                        </Popper>
                      </div>
                    </td> -->
                  </tr>
                </tbody>
              </table>
              <div class=""></div>
            </div>
          </div>
        </div>
      </template>
    </Datatable>
  </div>
  <ModalUploadGroup @getList="$emit('getList')" />
</template>

<script>
import Datatable from "@/components/kt-datatable/KTDatatableSottosezione.vue";
import ModalAddPerson from "./ModalAddPerson.vue";

import { ref } from "vue";
import { callFunctionAfterAlert } from "@/functions/swAlert";
import { API } from "@/enums/costanti";
import { formatDateITANoTime, isMaggiorenne } from "@/functions/formatDate.js";
import EtichettaGruppo from "@/components/groups/EtichettaGruppo.vue";
import ModalUploadGroup from "@/components/groups/ModalUploadGroup.vue";
import Popper from "vue3-popper";
export default {
  name: "TableTornei",
  components: {
    Datatable,
    ModalAddPerson,
    EtichettaGruppo,
    ModalUploadGroup,
    Popper,
  },
  emits: ["getList", "resetFilters", "setPayload"],
  props: {
    objectList: {
      type: Object,
    },
    loaded: {
      type: Boolean,
    },
    status: {
      type: Number,
    },
    payload: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const tableHeader = ref([
      {
        name: "Cognome",
        key: "cognome",
      },
      {
        name: "Nome",
        key: "nome",
      },
      {
        name: "Data nascita",
        key: "data_nascita",
      },
      {
        name: "Lingua parlata",
        key: "language",
      },
      {
        name: "Gruppo",
        key: "group",
      },
      {
        name: "Gruppo Accredito",
        key: "group_accreditation",
      },
      {
        name: "E-mail",
        key: "email",
      },

      {
        key: "options",
        sortable: false,
      },
    ]);
    const tableHeader2 = ref([
      {
        name: "Accompagnatori",
        key: "accompagnatori",
      },
    ]);

    const deleteElement = async (id_person, person_name) => {
      await callFunctionAfterAlert(
        API.ANAGRAPHICS_PEOPLE_DEL,
        { id_person },
        `Sei sicuro di voler cancellare <b>${person_name}</b>?`,
        "Persona cancellata con successo",
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then((deletedElement) => {
        if (deletedElement) {
          emit("getList");
        }
      });
    };

    const selected_id_person = ref(0);

    const currentPage = ref(1);

    const downloadModelloImport = () => {
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = "/media/gruppi/Modello_importazione_gruppo.csv";
      // link.setAttribute("download", `Modello_importazione_gruppo.csv`);
      document.body.appendChild(link);
      setTimeout(() => {
        link.click();
        document.body.removeChild(link);
      }, 500);
    };
    const isGuest = ref(false);

    return {
      isGuest,
      tableHeader2,
      downloadModelloImport,
      currentPage,
      tableHeader,
      deleteElement,
      selected_id_person,
      formatDateITANoTime,
      isMaggiorenne,
    };
  },
};
</script>

<style scoped>
.cerchio {
  position: relative;
  width: 15px; /* Dimensione del cerchio */
  height: 15px; /* Dimensione del cerchio */
  border-radius: 50%; /* Rende il cerchio */
  background-color: #3498db; /* Colore di riempimento */
  color: #fff; /* Colore del testo */
  font-size: 11px; /* Dimensione del testo */
  display: flex; /* Per allineare il testo verticalmente e orizzontalmente */
  justify-content: center;
  align-items: center;
}

:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
:deep(.popper) {
  /* position: fixed !important; */
  /* top: -100px !important;
  left: 0 !important;
  transform: translateY(-100%) !important; */
}
</style>
